<template>
  <div>
    <b-table
      ref="filesListTableCreative"
      primary-key="id"
      :items="paths"
      :fields="fields"
      table-class="text-nowrap"
      responsive="sm"
      :class="typeGrid=='VIEW' ? 'table-sidebar':''"   
      show-empty
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(filename)="data">
        <b-link
          class="text-primary"
          :href="data.item.url_full_path"
          target="_blank"
        >{{ data.item.filename }}</b-link>
      </template>

      <template #cell(url_preview_path)="data">
        <div>
          <b-link
            class="text-primary"
            :href="data.item.url_full_path"
            target="_blank"
          >
            <b-img
              :src="data.item.url_preview_path"
              height="50px"
              width="50px"
            />
          </b-link>
        </div>
      </template>

      <template #cell(files_size)="data">
        <div>
          {{ data.item.files_size }}
        </div>
      </template>

      <template #cell(actions)="data">
        <div class="text-center">
          <b-button
          size="sm"
          variant="danger"
          @click="deleteFile(data)"
          :disabled="typeGrid=='ONLY_VIEW' || typeGrid=='VIEW'"
          >
            <tabler-icon
              icon="TrashIcon"
              size="15"
              class="text-light cursor-pointer"              
              disabled
            />
          </b-button>
        </div>
      </template>

    </b-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import AdsService from '@/views/ads/views/settings/services/ads.service.js';
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request';

export default {
  components: {

  },
  props: {
    paths: {
      type: Array,
      required: false,
      default: null,
    },
    typeGrid: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: 'filename',
          label: 'NAME',
          visible: true,
        },
        {
          key: 'url_preview_path',
          label: 'PREVIEW VERSION',
          visible: true,
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          visible: true,
          class: 'text-center',
        },
      ],
      listDataFiles: [],
    };
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
  },
  created() {
  },
  methods: {
    async deleteFile(data) {
      const datax = data;
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        try {
          this.isBusy = true;
          this.addPreloader();
          const params = {
            contentGridId: datax.item.content_reference_id,
            userId: this.currentUser.user_id,
          };
          const result = await CreativeRequestService.deleteContentGridReference(params);
          this.isBusy = false;
          this.removePreloader();
          this.$emit('refreshDataRequest');
        } catch (error) {
          this.isBusy = false;
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
  },
};
</script>

