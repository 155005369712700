<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="text-right"
      >
        <b-button
          v-if="isCreativeDesign && isCarousel"
          variant="primary"
          class="mr-1"
          @click="addPieces()"
        ><feather-icon
          icon="PlusIcon"
          size="15"
        /> ADD PIECE</b-button>

        <b-button
          v-if="isCreativeDesign"
          variant="primary"
          :disabled="typeModal == 'ONLY_VIEW'"
          @click="addForm()"
        ><feather-icon
          icon="PlusIcon"
          size="15"
        />
          ADD {{ dataRequest.nameContentType.toUpperCase() }}
        </b-button>
      </b-col>

      <b-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="mt-1"
      >
        <div
          class="wizard-navigation"
          style="overflow:auto;"
        >
          <ul
            v-if="statusListPiece"
            class="nav nav-pills"
          >
            <li
              v-for="(value, index) in formDesignStore.content[idTypeStore].parrilla"
              :key="'li' + index"
              class="li_nav"
              @click="changeForm(index)"
            >
              <b-button
                :variant="index == idPiece ? 'primary' : isDarkSkin ? 'dark' : 'light' "
              >
                {{ index + 1 }}
              </b-button>
              <span
                class="title_tab"
                :class=" index == idPiece ? 'active_tab' : '' "
              > {{ dataRequest.nameContentType }}

                <feather-icon
                  icon="ChevronRightIcon"
                  size="16"
                  style="margin-bottom:2px;"
                />
              </span>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FormButtons',
  components: {

  },
  props: {
    dataRequest: {
      type: Object,
      required: false,
      default: null,
    },
    typeModal: {
      type: String,
      default: 'NEW',
      required: false,
    },
  },
  data() {
    return {
      btnActive: 0,
      statusListPiece: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
      formDesignStore: 'CreativeDesign/G_FORM_DESIGN_REQUEST',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreativeDesign() {
      return this.$route.matched[0].meta.module == 27;
    },
    isCarousel() {
      return this.dataRequest.content_type == 7;
    },
  },
  mounted() {
    setInterval(() => {
      this.statusListPiece = true;
    }, 100);
  },
  watch: {
    // "dataRequest.content"(newVal){
    //   if (newVal) {
    //     this.dataRequest();
    //   }
    // }
  },
  methods: {
    ...mapActions({
      UPDATE_ID_PIECE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    changeForm(index) {
      this.btnActive = index;
      this.UPDATE_ID_PIECE_CREATIVE_DESIGN(index);
      this.$emit('changeButton', index);
    },
    async addForm() {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        this.$emit('addFormFields');
      }
    },
    addPieces() {
      this.$emit('addPieces');
    },
    updateParrilla(forms) {
      this.dataRequest.content[this.idTypeStore].parrilla = forms.content[this.idTypeStore].parrilla;
    }
  },
};
</script>
<style scoped>
.nav-pills{
    display:flex;
    width:max-content;
}
.nav-pills > li > a {
    border: 0 !important;
    border-radius: 0;
    line-height: 18px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    text-align: center;
    color: #fff9 !important;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.title_tab{
    font-weight:bold;
    cursor:pointer;
    margin-left: 2px;
}
.title_tab:hover, .active_tab{
    color:rgb(115, 103, 240);
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 500px;
  height: 30px;
  border:solid 1px #f00;
}
.li_nav{
  width:auto;
}
</style>
