<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        {{ listData }}
        <div class="wizard-navigation">
          <ul class="nav nav-pills">
            <li
              v-for="(value, index) in nameContentType"
              :key="'li' + index"
              class="li_nav"
            >
              <a
                v-if="value"
                data-toggle="tab"
                aria-expanded="true"
              >CAROUSEL {{ index + 1 }}</a>
            </li>
          </ul>

          <div
            v-for="(valuex, index) in nameContentType"
            :key="'cont' + index"
            class="container"
          >
            <div class="box">
              <b-row>
                <b-col
                  v-for="(value, keyx) in valuex"
                  v-if="value"
                  :key="'list'+keyx"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="mt-1"
                >
                  <span
                    v-if="value"
                    class="text-capitalize"
                  >
                    {{ value.name }}
                  </span>
                  <div
                    v-if="value && value.type == 'textarea' "
                  >
                    <b-form-textarea
                      v-model="value.value"
                    />
                  </div>
                  <div
                    v-if="value && value.type == 'tags' "
                  >
                    <b-form-tags
                      v-model="value.value"
                      input-id="tags-basic"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContentTypesPiece',
  components: {

  },
  props: {
    dataRequest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      Solutions: [1, 2, 3, 4],
      keywords: ['Credit', 'Experts', 'Solutions'],
      fields: [
        {
          key: '1001', name: 'start', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1002', name: 'body', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1003', name: 'outcome', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1004', name: 'end', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1005', name: 'closing', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1006', name: 'keywords', visible: false, value: null, type: 'tags',
        },
        {
          key: '1007', name: 'notes', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1008', name: 'references', visible: false, value: null, type: 'textarea',
        },
        {
          key: '1009', name: 'text', visible: false, value: null, type: 'textarea',
        },
      ],
      listData: [],
    };
  },
  async created() {

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    igtv() {
      return this.fields.map(field => {
        if (field.key !== 'text') {
          field.visible = true;
          return field;
        }
      });
    },
    story() {
      return this.fields.map(field => {
        if (field.key === 'text' || field.key === 'keywords'
        || field.key === 'notes' || field.key === 'references') {
          field.visible = true;
          return field;
        }
      });
    },
    carousel() {
      return this.fields.map(field => {
        if (field.key === 'keywords' || field.key === 'notes' || field.key === 'references') {
          field.visible = true;
          return field;
        }
      });
    },
    video() {
      return this.fields.map(field => {
        if (field.key !== 'text') {
          field.visible = true;
          return field;
        }
      });
    },
    reel() {
      return this.fields.map(field => {
        if (field.key !== 'text') {
          field.visible = true;
          return field;
        }
      });
    },
    flyer() {
      this.listData = [];
      let numx = 0;
      for (let index = 0; index < parseInt(this.dataRequest.pieces_qty); index++) {
        // this.fields.forEach(field => {
        //   if (field.name === 'text' || field.name === 'keywords'
        //   || field.name === 'notes' || field.name === 'references') {
        //     field.visible = true;
        //     field.key = parseInt(field.key) + numx;
        //   }else{
        //     field = null;
        //   }
        // });
        // numx++;
        // console.log(this.fields);
        
        this.fields.map(field => {
          if (field.name === 'text' || field.name === 'keywords'
          || field.name === 'notes' || field.name === 'references') {
            field.key = numx;
            field.visible = true;
            return field;
          }
        });

        // console.log(this.listData);
        this.listData.push(this.fields);
      }
      return this.listData;
    },
    nameContentType() {
      switch (this.dataRequest.content_type) {
        case 9:
          return this.gif;
        case 10:
          return this.flyer;
        case 11:
          return this.igtv;
        case 12:
          return this.reel;
        case 13:
          return this.video;
        case 14:
          return this.story;
        case 15:
          return this.carousel;
        default:
          return 'Tipo de contenido desconocido';
      }
    },

  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style scoped>
.wizard-container .wizard-navigation {
    position: relative;
}
.nav-pills{
    display:flex;
}
.nav-pills > li > a {
    border: 0 !important;
    border-radius: 0;
    line-height: 18px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    text-align: center;
    color: #fff9 !important;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.li_nav{
    background:#2f2f31;
}

/* animacion */
.container{
    height:100%;
    border:solid 1px #eee;
}
@keyframes slideright {
    from {
        margin-left: 0%;
        width:250px;
    }
    to {
        margin-left: 100%;
        width:0px;
    }
}
.container .box {
    /* border:solid 1px #e00; */
    /* animation: slideright 4s infinite; */
}
</style>
