<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xmd"
      :title="titleModal"
      scrollable
      no-close-on-backdrop
      @hide="closeModal"
    >
      <div
        :style=" viewNumber == 1 ? 'display:block' : 'display:none' "
      >
        <validation-observer ref="form">
          <b-row>
            <b-col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="mt-2"
            >
              <b-row>
                <b-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text
                          class="bg-primary text-white min-w-160"
                          style="border-radius: 8px 8px 0 0;"
                        >
                          <span>PROGRAM</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                    </b-input-group>

                    <div style="overflow:auto;">
                      <div
                        class="d-flex justify-content-center mx-auto"
                        style="    min-width: 100%; width:fit-content;"
                        :class="[isDarkSkin ? 'conten_programs_dark' : 'conten_programs_light', errors.length > 0 ? 'error_input' : ''
                        ]"
                      >
                        <div
                          v-for="(program, index) in programs"
                          :key="program.id"
                          :class="[isDarkSkin ? 'conten_ico_program_dark' : 'conten_ico_program_light',
                                  program.id == form.program_selected ? 'active_program' : '' ]"
                          @click="changeProgram(program)"
                        >
                          <figure
                            class="mb-0 text-center"
                          >
                            <b-img
                              :style="cropImage(program.id)"
                              :src="`${baseImg}/assets${program.logo}`"
                            />
                            <b-form-radio
                              v-model="form.program_selected"
                              name="some-radios"
                              class="px-1"
                              :value="program.id"
                              :disabled="isParagonModule || typeModal == 'ONLY_VIEW'"
                            >{{ program.value }}</b-form-radio>
                          </figure>
                        </div>
                      </div>
                    </div>

                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row 
                class="mt-2"
              >
                <b-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                    <b-form-group>
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-input-group-text
                            class="bg-primary text-white min-w-160"
                          >
                            <span>NAME DOCUMENT</span>
                          </b-input-group-text>
                        </b-input-group-prepend>

                        <b-form-input
                          v-model="requestName"
                          placeholder="Name Document"
                          :disabled="typeModal == 'ONLY_VIEW' || (form.usage_date == null || form.program_selected == null)"
                          @change="editedName"
                        />

                      </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-input-group-text
                            class="bg-primary text-white min-w-160"
                          >
                            <span>USAGE DATE</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <kendo-datepicker
                          v-model="form.usage_date"
                          :start="'year'"
                          :depth="'year'"
                          :format="'yyyy-MM'"
                          placeholder="YYYY-MM"
                          :class="{ 'border-danger': errors[0] }"
                          class="form-control bg-transparent"
                          :min="minDate"
                          :state="errors.length > 0 ? false : null "
                          :disabled="typeModal == 'ONLY_VIEW'"
                        />
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>                  
                </b-col>

                <b-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-form-group>
                      <b-input-group 
                        :class=" errors.length > 0 ? 'content_flat' : '' "
                      >
                        <b-input-group-prepend>
                          <b-input-group-text
                            class="bg-primary text-white min-w-160"
                          >
                            <span>DEADLINE</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <flat-pickr
                          v-model="form.request_date"
                          class="form-control cursor-pointer text-center"
                          placeholder="Deadline to be defined"
                          style="border:solid 1px #00f;"
                          :disabled="isParagonModule || typeModal == 'ONLY_VIEW'"
                          :config="configDate"
                        />

                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row>
                  <b-col
                    cols="12" xs="12" sm="12" md="6" lg="12" xl="12"
                    v-for="(value, index) in form.content" 
                    :key="index + 'pieces'"
                  >
                    <b-row>
                      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            max: 100,
                            max_value: 100,
                            min_value: 1,
                          }"
                        >
                          <b-form-group>
                            <b-input-group class="flex-nowrap">
                              <b-input-group-prepend>
                                <b-input-group-text
                                  class="bg-primary text-white min-w-160"
                                >
                                  <span>CONTENT TYPE</span>
                                </b-input-group-text>
                              </b-input-group-prepend>

                              <v-select
                                v-model="form.content[index].content_type"
                                label="name"
                                :reduce="(val) => val.id"
                                :options="materialTypes"
                                class="w-100"
                                :clearable="false"
                                :class=" errors.length > 0 ? 'v_select' : '' "                              
                                :selectable="(option) => {                       
                                  return !materialSelecteds.includes(option.id);
                                }"
                                @input="changeContentType(index)"
                                :disabled="typeModal == 'ONLY_VIEW'"
                              />

                              <b-input-group-append
                                v-if="index === 0"
                              >
                                <b-button
                                  size="sm"
                                  :variant="isDarkSkin ? 'primary' : 'light'"
                                  readonly
                                  style="border-radius:50%; margin-left:10px;font-weight:bold;font-size:15px;padding:5px 11px;"
                                  @click="addContent()"
                                  :disabled="form.content.length >= materialTypesCpy.length || isParagonModule || typeModal == 'ONLY_VIEW' "
                                ><tabler-icon icon="PlusIcon" /></b-button>
                              </b-input-group-append>

                              <b-input-group-append
                                v-else
                              >
                                <b-button
                                size="sm"
                                variant="danger"
                                readonly
                                style="border-radius:50%; margin-left:10px;font-weight:bold;font-size:15px;padding:5px 11px;"
                                @click="deleteContent(value, index)"
                                :disabled="isParagonModule || (currentTabStatus && currentTabStatus != 'pending') || typeModal == 'ONLY_VIEW' "
                                ><tabler-icon icon="TrashIcon" /></b-button>
                              </b-input-group-append>

                            </b-input-group>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{
                            required: true,
                            max: 100,
                            max_value: 100,
                            min_value: 1,
                          }"
                        >
                          <b-form-group
                            label-for="title"
                          >
                            <b-input-group class="flex-nowrap">
                              
                              <b-input-group-prepend>
                                <b-input-group-text
                                  class="bg-primary text-white min-w-160"
                                >
                                  <span>PIECES QUANTITY</span>
                                </b-input-group-text>
                              </b-input-group-prepend>
                              
                              <div
                                v-b-tooltip.hover.top="textTooltipDisableEditPieces"
                                v-b-tooltip.hover.v-primary
                                class="w-100"
                              >
                                <b-form-input
                                  v-model="form.content[index].pieces_qty"
                                  placeholder="Pieces quantity"
                                  type="number"
                                  :state="errors.length > 0 ? false : null "
                                  :disabled="form.content[index].pieces_qty > 0 ? disableEditPieces : false "
                                />
                              </div>

                            </b-input-group>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <b-input-group class="flex-nowrap">
                      <b-input-group-prepend>
                        <b-input-group-text
                          class="bg-primary mt-1 justify-content-center text-white min-w-160"
                          style="border-radius:8px 8px 0 0"
                        >
                          <span>NOTES</span>
                        </b-input-group-text>
                      </b-input-group-prepend>
                    </b-input-group>
                    <b-form-textarea
                      v-model="form.observation"
                      :disabled="isParagonModule || typeModal == 'ONLY_VIEW'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
        </validation-observer>
      </div>

      <div
        v-if="viewNumber == 2"
      >
        <div
          v-if="typeModal != 'VIEW' "
          class="px-2"
        >
            <form-buttons-type
            :data-request="form"
            :type-modal="typeModal"
            :status-form-global="formStatusGlobal"
            @createSubTab="createSubTab"
            @saveNotification="saveNotification"
            @changeFormStatus="changeFormStatus"
            />
            
            <form-buttons
              v-if="statusButtonPiece"
              :data-request="form"
              :type-modal="typeModal"
              @addFormFields="addFormFields"
              @changeButton="changeForm"
            />
        </div>

        <div
          v-if="typeModal != 'VIEW' || typeModal == 'ONLY_VIEW'"
        >
          <b-button
            variant="primary"
            class="mr-1 btn_before"
            :disabled=" idPiece > 0 ? false : true "
            @click="beforeItem()"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="12"
            />
          </b-button>

          <b-button
            v-if="statusNext"
            variant="primary"
            class="ml-1 btn_after"
            :disabled="(idPiece + 1) < form.content[idTypeStore].parrilla.length ? false : true"
            @click="validateNextBtn()"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="12"
            />
          </b-button>
        </div>

        <div v-if="statusIgtv">
          <div
            v-if="form.content[idTypeStore] && (form.content[idTypeStore].content_type == 3 || form.content[idTypeStore].content_type == 4 || form.content[idTypeStore].content_type == 5)"
            style="padding:0 20px;"
          >
            <form-igtv-video-reel
              ref="component_form_igtv"
              :data-request="form"
              :type-modal="typeModal"
              @refresh-data-request-creative="refreshDataRequestCreative"
              @changeFormStatus="changeFormStatus"
            />
          </div>
        </div>

        <div v-if="statusFlyer">
          <div
            v-if="form.content[idTypeStore] && (form.content[idTypeStore].content_type == 6 || form.content[idTypeStore].content_type == 2 || form.content[idTypeStore].content_type == 1)"
            style="padding:0 20px;"
          >
            <form-story-flyer
              ref="component_form_flyer"
              :data-request="form"
              :type-modal="typeModal"
              :select-form="selectForm"
              @refresh-data-request-creative="refreshDataRequestCreative"
              @changeFormStatus="changeFormStatus"
            />
          </div>
        </div>
        
        <div v-if="statusCarousel">
          <div
            v-if="form.content[idTypeStore] && form.content[idTypeStore].content_type == 7 "
            style="padding:0 20px;"
          >
            <form-carousel
              ref="component_form_carousel"
              :data-request="form"
              :type-modal="typeModal"
              @deletePiecesCarousel="deletePiecesCarousel"
              @refresh-data-request-creative="refreshDataRequestCreative"
              @changeFormStatus="changeFormStatus"
            />
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col
              cols="12"
              xs="12"
              sm="12"
              md="3"
              lg="3"
              xl="3"
            >
              <b-button
                v-if="viewNumber > 1 && idPiece > 0 && isCreativeDesign && isPendingTab "
                variant="danger"
                class="px-1 ml-1"
                @click="deleteItems()"
              ><tabler-icon
                icon="TrashIcon"
                size="15"
              /></b-button>
            </b-col>

            <b-col
              cols="12"
              xs="12"
              sm="12"
              md="9"
              lg="9"
              xl="9"
              class="text-right"
            >
              <b-button
                v-if="viewNumber > 1 && typeModal != 'VIEW'"
                variant="info"
                class="px-1 mr-1"
                @click="activeView(1)"
              ><tabler-icon
                icon="ArrowBackUpIcon"
                size="15"
              />
                GO TO FORM</b-button>

              <b-button
              v-if="viewNumber < 2"
                variant="info"
                class="px-1 mr-1"
                @click="activeView(2)"
              ><tabler-icon
                icon="CornerDownRightIcon"
                size="15"
              />
                GO TO CONTENT GRID</b-button>

              <b-button
                v-if="typeModal == 'NEW' && moduleId != 12 && viewNumber > 1"
                v-b-tooltip.hover.top=" statusForm ? '' : 'Complete the fields' "
                variant="primary"
                class="px-1"
                @click="save(true)"
              ><feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-1"
              />SAVE AS DRAFT</b-button>

              <b-button
                v-if="typeModal == 'NEW' && moduleId != 12 && viewNumber > 1"
                v-b-tooltip.hover.top=" statusForm ? '' : 'Complete the fields' "
                variant="primary"
                class="px-1 ml-1"
                @click="save(false)"
              ><feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-1"
              />SEND TO PARAGON</b-button>

              <b-button
                v-if="typeModal == 'EDIT' && !isParagonModule && viewNumber == 2"
                variant="primary"
                class="px-1 mr-1"
                @click="update()"
              >UPDATE 
                <span>{{ form.content[idTypeStore].name }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>

    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import CreativeDesignService from '@/views/creative/views/creative-requests/service/CreativeDesign.service.js';
import DragAndDrop from '@/views/commons/utilities/DragAndDrop.vue';
import ContentTypePieces from '@/views/creative/views/creative-requests/components/modals/ContentTypesPieces.vue';
import FormIgtvVideoReel from '@/views/creative/views/creative-requests/components/modals/FormIgtvVideoReel.vue';
import FormStoryFlyer from '@/views/creative/views/creative-requests/components/modals/FormStoryFlyer.vue';
import FormCarousel from '@/views/creative/views/creative-requests/components/modals/FormCarousel.vue';
import FormButtons from '@/views/creative/views/creative-requests/components/modals/FormButtons.vue';
import FormButtonsType from '@/views/creative/views/creative-requests/components/modals/FormButtonsType.vue';
import { swalWarningIcon } from "@/icons/statusIcons"

import flatPickr from 'vue-flatpickr-component';
import { v4 as uuidv4 } from 'uuid'
import moment from "moment"

export default {
  components: {
    DragAndDrop,
    ContentTypePieces,
    FormIgtvVideoReel,
    FormStoryFlyer,
    FormCarousel,
    flatPickr,
    FormButtons,
    FormButtonsType,
  },
  props: {
    typeModal: {
      type: String,
      default: 'NEW',
      required: false,
    },
    idRequest: {
      type: Number,
      default: 0,
      required: false,
    },
    dataRequest: {
      type: Object,
      required: false,
      default: null,
    },
    currentTabStatus: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      showModal: false,
      form: {
        program_selected: null,
        request_date: null,
        content_type: null,
        initial: null,
        observation: null,
        pieces_qty: null,
        variants_qty: null,
        idRequest: null,
        usage_date: null,
        content: [
          {
            pieces_qty: null,
            content_type: null,
            parrilla: [],
          },
        ],
        parrilla: [],
        nameContentType: '',
        is_draft: 0,
      },
      programs: [],
      materialTypes: [],
      materialTypesCpy: [],
      files: [],
      nameDocument: null,
      viewNumber: 1,
      statusForm: false,
      selectFormId: 0,
      statusEditPieces: false,
      configDate: {
        allowInvalidPreload: true,
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        altFormat: 'm/d/Y h:i K',
        altInput: true,
        time_24hr: true,
        minDate: 'today',
      },
      arrayFiles: [],
      statusButtonPiece: true,

      statusFlyer: true,
      statusCarousel: true,
      statusIgtv: true,
      materialSelecteds: [],
      getRequestCompleted: false,
      statusNext: false,
      enableAutoGeneratedName: true,

      validateForms: null,
      numViewForm: null,
      formStatusGlobal: false,

      validateFormRequest: false,

    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    titleModal() {
      const newOrEdit = {
        'NEW': 'NEW',
        'EDIT': 'EDIT',
        'ONLY_VIEW': 'VIEW'
      }
      const contentToSee = this.viewNumber > 1 ? 'REQUEST (CONTENT GRID)' : 'REQUEST (FORM)';
      const fullTitle = `${newOrEdit[this.typeModal]} ${contentToSee}`;
      return this.isParagonModule ? (this.viewNumber == 2 && this.typeModal == 'VIEW') ? 'GUIDE' : contentToSee : fullTitle;
    },
    titleButton() {
      return this.typeModal == 'ADD' ? 'SAVE' : 'UPDATE';
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    disableEditPieces() {
      return this.moduleId == 12 || this.statusEditPieces;
    },
    isParagonModule() {
      return this.moduleId == 12;
    },
    isCreativeDesign() {
      return this.$route.matched[0].meta.module == 27;
    },
    disableEditContentType() {
      return this.isParagonModule || this.typeModal == 'EDIT';
    },
    textTooltipDisableEditPieces() {
      return this.disableEditPieces ? 'If you want to add more pieces, you can do it in the Content Grid option' : '';
    },
    totalParrila() {
      return this.form.parrilla.length;
    },
    requestName(){      
      if(this.enableAutoGeneratedName){
        let baseName = "Content grid ";
        if(this.typeModal != 'EDIT'){
          if(this.form.program_selected == null || this.form.usage_date == null){
            return this.nameDocument;
          }
          
          let programName = this.programs.filter(item => item.id == this.form.program_selected)[0].value;
          let fechaFormateada = moment(this.form.usage_date).format('MMMM YYYY');
          let name = baseName + programName + ' - ' + fechaFormateada;
          this.nameDocument = name;
          this.nameDocumentCpy = name;
          return name;
        }else{
          if(this.getRequestCompleted){
            let fechaFormateada = moment(this.form.usage_date).format('MMMM YYYY');
            let programName = this.programs.filter(item => item.id == this.form.program_selected)[0].value;
            let name = baseName + programName + ' - ' + fechaFormateada;
            this.nameDocument = name;
            this.nameDocumentCpy = name;
            return name;
          }
        }
      }else{
        return this.nameDocument;
      }
    },
    minDate() {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    isPendingTab() {
      return this.$route.meta.status == 'pending';
    }
  },
  watch: {

  },
  async created() {
    try {
      this.addPreloader();
      await this.getPrograms();
      await this.getMaterialTypes();
      this.showModal = true;
      if (this.typeModal == 'EDIT' || this.typeModal == 'ONLY_VIEW') {
        this.statusEditPieces = true;
        await this.getRequest();
        this.nameContentType(this.form.content_type);
        this.removePreloader();
      }
    } catch (error) {
      this.removePreloader();
    } finally {
      this.removePreloader();
    }
  },
  mounted() {
    if (this.typeModal == 'VIEW') {
      this.getRequestContentGrid();
    }
  },
  methods: {
    ...mapActions({
      UPDATE_ID_TYPE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      UPDATE_ID_PIECE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_PIECE_CREATIVE_DESIGN',
      FORM_DESIGN_REQUEST: 'CreativeDesign/A_FORM_DESIGN_REQUEST',
    }),
    editedName(value){
      this.enableAutoGeneratedName = false
      this.nameDocument = value;
    },
    async save(isDraft) {
      try {
        if(!isDraft){
          let idTypeStorez = this.form.content[this.idTypeStore].content_type;
          console.log(idTypeStorez);
          if(idTypeStorez == 3 || idTypeStorez == 4 || idTypeStorez == 5) {
            const validateComponentForm = await this.$refs.component_form_igtv.validateForm();
            if(validateComponentForm == false){
              return false;
            }
          } else if(idTypeStorez == 1 || idTypeStorez == 2 || idTypeStorez == 6) {
            const validateComponentForm = await this.$refs.component_form_flyer.validateForm();
            if(validateComponentForm == false){
              return false;
            }
          } else if(idTypeStorez == 7) {
            const validateComponentForm = await this.$refs.component_form_carousel.validateForm();
            if(validateComponentForm == false){
              return false;
            }
          }
        }

        this.statusForm = await this.$refs.form.validate();
        if (this.statusForm) {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            if (this.statusForm) {

              this.form.content.forEach(content => {          
                content.parrilla.forEach(item => {
                  let auxFile = item.files.map(filez => {
                    let uuid = uuidv4();
                    let auxArrayFiles = {
                      uuid: uuid,
                      file: filez.fileapproved
                    }
                    this.arrayFiles.push(auxArrayFiles);
                    return {
                      uuid: uuid,    
                      name: filez.name         
                    }
                  })    
                  item.files = auxFile;        
                })
              })
              this.addPreloader();
              const params = {
                designRequestId: null,
                programId: this.form.program_selected,
                requestDate: this.form.request_date,
                contentType: this.form.content_type,
                piecesQty: this.form.pieces_qty,
                //variantQty: this.form.variants_qty,
                programInitial: this.form.initial,
                nameDocument: this.nameDocument,
                userId: this.currentUser.user_id,
                observation: this.form.observation,
                parrilla: this.form.parrilla,
                content: this.form.content,
                files: this.arrayFiles,
                isDraft: isDraft,
                isCustomizedName: !this.enableAutoGeneratedName,
                usageDate: moment(this.form.usage_date, 'YYYY-MM').format('YYYY-MM-DD')
              };
              await CreativeDesignService.saveRequest(params);
              this.removePreloader();
              this.showSuccessSwal('Success!', 'Request saved successfully!');
              this.refreshTable();
              this.showModal = false;
            }
          }
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    showConfirmSwal2(
      title = 'Are you sure?',
      text = 'You won\'t be able to revert this!',
      config = {},
    ) {
      return this.$swal({
        title,
        text,
        imageWidth: 70,
        showCancelButton: true,
        imageUrl: swalWarningIcon,
        buttonsStyling: false,
        showDenyButton: true,
        denyButtonText: 'Update',
        confirmButtonText: 'Update and close',
        customClass: {
          confirmButton: 'btn btn-primary mr-1',
          cancelButton: 'btn btn-outline-danger',
          denyButton: 'btn btn-info mr-1',
        },
        ...config,
      });
    },
    async update() {
      if(this.form.is_draft) {
        this.validateFormRequest = true;
      } else {
        let content_type_id = this.form.content[this.idTypeStore].content_type;
        if(content_type_id == 1 || content_type_id == 2 || content_type_id == 6) {
          this.validateForms = this.$refs.component_form_flyer.validateForm();
        } else if(content_type_id == 3 || content_type_id == 4 || content_type_id == 5) {
          this.validateForms = this.$refs.component_form_igtv.validateForm();
        } else if(content_type_id == 7) {
          this.validateForms = this.$refs.component_form_carousel.validateForm();
        }
        try {
          this.validateFormRequest = await this.validateForms;
        } catch (error) {
          console.error(error);
        }
      }

      try {
        this.form.parrilla = this.form.content[this.idTypeStore].parrilla;
        if(this.form.parrilla.length > 0){
          this.form.parrilla.forEach(item => {
            item.pieces_qty = this.form.content[this.idTypeStore].pieces_qty;
            item.content_type = this.form.content[this.idTypeStore].content_type;
            item.content_name = this.form.content[this.idTypeStore].name;
            let auxFile = item.files.map(filez => {
              let uuid = uuidv4();
              let auxArrayFiles = {
                uuid: uuid,
                file: filez.fileapproved
              }
              this.arrayFiles.push(auxArrayFiles);
              return {
                uuid: uuid,     
                name: filez.name    
              }
            })    
            item.files = auxFile;        
          })
        }    

        const resultForm = await this.$refs.form.validate() && this.validateFormRequest;
        if (resultForm) {
          if(this.numViewForm == 1){
              
            this.addPreloader();
            const params = {
              programId: this.form.program_selected,
              requestDate: this.form.request_date,
              contentType: this.form.content_type,
              piecesQty: this.form.pieces_qty,
              variantQty: this.form.variants_qty,
              programInitial: this.programs.find(item => item.id == this.form.program_selected).initial,
              nameDocument: this.nameDocument,
              userId: this.currentUser.user_id,
              observation: this.form.observation,
              idRequest: this.form.content[this.idTypeStore].content_id == undefined ? null : this.form.content[this.idTypeStore].content_id,
              parrilla: this.form.parrilla,
              content: this.form.content,
              files: this.arrayFiles,
              idRequestPrincipal: this.idRequest,
              isCustomizedName: !this.enableAutoGeneratedName,
              usageDate: moment(this.form.usage_date, 'YYYY-MM').format('YYYY-MM-DD')
            };
            await CreativeDesignService.updateRequest(params);
            this.removePreloader();
            this.showSuccessSwal('Success!', 'Request saved successfully!');
            this.refreshTable();
            if(confirm.isConfirmed){
              this.showModal = false;
            }

          }else{
            const confirm = await this.showConfirmSwal2();
            if (confirm.isConfirmed || confirm.isDenied) {
              this.addPreloader();
              const params = {
                programId: this.form.program_selected,
                requestDate: this.form.request_date,
                contentType: this.form.content_type,
                piecesQty: this.form.pieces_qty,
                variantQty: this.form.variants_qty,
                programInitial: this.programs.find(item => item.id == this.form.program_selected).initial,
                nameDocument: this.nameDocument,
                userId: this.currentUser.user_id,
                observation: this.form.observation,
                idRequest: this.form.content[this.idTypeStore].content_id == undefined ? null : this.form.content[this.idTypeStore].content_id,
                parrilla: this.form.parrilla,
                content: this.form.content,
                files: this.arrayFiles,
                idRequestPrincipal: this.idRequest,
                isCustomizedName: !this.enableAutoGeneratedName,
                usageDate: moment(this.form.usage_date, 'YYYY-MM').format('YYYY-MM-DD')
              };
              await CreativeDesignService.updateRequest(params);
              this.removePreloader();
              this.showSuccessSwal('Success!', 'Request saved successfully!');
              this.refreshTable();
              if(confirm.isConfirmed) {
                this.showModal = false;
              }

              if (!this.form.content[this.idTypeStore].content_id) { //not id type request
                this.getRequest().then(result => {
                  this.createSubTab();
                });
              } else {
                this.createSubTab();
              }
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    closeModal() {
      this.UPDATE_ID_TYPE_CREATIVE_DESIGN(0);
      this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
      this.idRequest == null;
      this.$emit('closeModal');
    },
    refreshTable() {
      this.$emit('refreshTable');
    },
    async getPrograms() {
      const { data } = await CreativeDesignService.getPrograms();
      this.programs = data;
    },
    async getMaterialTypes() {
      const { data } = await CreativeDesignService.getMaterialTypes();
      data.forEach(element => {
        if (element.id === 9 || element.id === 11) {
          element.name = element.name.toUpperCase();
        }
      });
      this.materialTypes = data;
      this.materialTypesCpy = data;      
    },
    cropImage(id) {
      switch (id) {
        case 1: // BC
          return {
            height: '43px',
            width: '70px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          };
        case 2:
          return { // BC
            height: '41px',
            width: '86px',
            objectFit: 'cover',
            objectPosition: '0 -17%',
            margin: '0 0 3px 0',
          };
        case 3: // CE
          return {
            height: '40px',
            width: '39px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
            margin: '0 0 4px 0',
          };
        case 4: // DS
          return {
            height: '42px',
            width: '57px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
            margin: '0 0 2px 0',
          };
        case 5: // TR
          return {
            height: '39px',
            width: '72px',
            objectFit: 'cover',
            objectPosition: '20% -5%',
            margin: '0 0 5px 0',
          };
        case 6:
          return {
            height: '41px',
            width: '50px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          };
        case 8:
          return {
            height: '41px',
            width: '50px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
          };
        case 10:
          return {
            height: '41px',
            width: '36px',
            objectFit: 'cover',
            objectPosition: '20% 10%',
            margin: '0 0 1px 0',
          };
        default:
          break;
      }
    },
    changeProgram(program) {
      if(this.typeModal != "ONLY_VIEW"){
        this.form.program_selected = program.id;
        this.form.initial = program.initial;
      }
    },
    async getRequest() {
      try {
        const params = {
          design_request_id: this.idRequest,
        };
        const { data } = await CreativeDesignService.getRequestDesign(params);
        const form_data = data[0];

        let parrilla_data = [];
        if (form_data.content_type_id == 7) {
          parrilla_data = form_data.parrilla;
        } else {
          parrilla_data = JSON.parse(form_data.content);
        }

        this.form.program_selected = form_data.program_id;
        this.nameDocument = form_data.document_name;
        this.form.request_date = form_data.due_date;
        this.form.content_type = form_data.content_type_id;
        this.form.pieces_qty = form_data.pieces_quantity;
        this.form.variants_qty = form_data.variants_quantity;
        this.form.observation = form_data.observation;
        this.form.idRequest = form_data.designRequestId;
        this.form.parrilla = parrilla_data;
        this.form.content = JSON.parse(form_data.content);
        this.form.usage_date = moment(form_data.usage_date).format('YYYY-MM')
        this.form.is_draft = form_data.is_draft;
        this.enableAutoGeneratedName = form_data.is_customized_name == 0 ? true : false;
        this.getRequestCompleted = true;
        this.filterOptions();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    filterOptions(){
      this.materialSelecteds = [];
      let material = this.materialTypesCpy.filter(material => {
        return this.form.content.some(item => item.content_type == material.id)
      })
      material.forEach(item => {
        this.materialSelecteds.push(item.id);
      });
    },
    async activeView(num) {
      if(this.typeModal == 'EDIT') {
        this.numViewForm = num;
      }

      if(num == 1 && this.typeModal == 'EDIT') {
          let title = 'Are you sure?';
          let text = "If you go back to the previous step, please note that you will lose all unsaved form information. Do you want to save the changes before proceeding?";
          let swalInfoIcon = '';
          this.$swal({
            title,
            text,
            imageUrl: '/assets/images/icons/swal/warning.svg',
            imageWidth: 70,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: "Save",
            denyButtonText: 'Dont save',
            cancelButtonText: "Cancel",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
              denyButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.update();
              this.viewNumber = num;
              this.statusEditPieces = true;
            }else if(result.isDenied){ //not saved
              this.viewNumber = num;
              this.statusEditPieces = true;
            }else if(result.isDismissed){ //cancel
              
            }
        })
      }else{
        this.statusForm = await this.$refs.form.validate();
        if (this.statusForm) {
          this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
          this.viewNumber = num;
          this.statusEditPieces = true;
        }
        
      }
    },
    selectForm(id) {
      this.selectFormId = id;
    },
    async deleteItems() {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        if (this.form.content[this.idTypeStore].parrilla[this.idPiece].content_grid_id) {
          try {
            this.form.content[this.idTypeStore].pieces_qty = this.form.parrilla.length - 1;
            this.form.content[this.idTypeStore].parrilla[this.idPiece].deleted = true;
            this.addPreloader();
            const params = {
              design_request_id: this.form.content[this.idTypeStore].content_grid_id,
              content_type_id: this.form.content[this.idTypeStore].content_type,
              userId: this.currentUser.user_id,
              program_initial: '',
              parrilla: this.form.content[this.idTypeStore].parrilla[this.idPiece],
            };
            const result_delete = await CreativeDesignService.deleteContentGrid(params);
            this.form.content[this.idTypeStore].parrilla.splice(this.idPiece, 1); //delete
            this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
            this.removePreloader();
            this.showSuccessSwal('Success!', 'Request saved successfully!');
            this.refreshTable();
          } catch (error) {
            this.removePreloader();
            this.showErrorSwal(error);
          }
        }else{
          //delete new form
          this.form.content[this.idTypeStore].parrilla[this.idPiece].deleted= true;
          this.form.content[this.idTypeStore].pieces_qty = this.form.content[this.idTypeStore].parrilla.length - 1;
          this.form.content[this.idTypeStore].parrilla.splice(this.idPiece, 1); //delete
          this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
        }
      }
    },
    async editPiecesQuantity() {
      const confirm = await this.showConfirmSwal();
      if (confirm.value) {
        this.statusEditPieces = false;
      }
    },
    changeContentType(index) {
      const result = this.materialTypes.find(field => field.id == this.form.content[index].content_type);
      this.form.content[index].name = result.name;
      this.filterOptions();
    },
    nameContentType(contentTypeId) {
      const result = this.materialTypes.find(field => field.id == contentTypeId);
      this.form.nameContentType = result.name;
    },
    async deletePiecesCarousel(index) {
      try {
        this.addPreloader();
        const params = {
          programId: this.form.program_selected,
          requestDate: this.form.request_date,
          contentType: this.form.content_type,
          piecesQty: this.form.pieces_qty,
          variantQty: this.form.variants_qty,
          programInitial: this.form.initial,
          nameDocument: this.nameDocument,
          userId: this.currentUser.user_id,
          observation: this.form.observation,
          idRequest: this.form.idRequest,
          parrilla: this.form.parrilla,
        };
        await CreativeDesignService.updateRequest(params);
        this.removePreloader();
        this.form.parrilla[this.idPiece].pieces.splice(index, 1);
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    addContent(){
       this.form.content.push({
          pieces_qty: null,
          content_type: null,
          parrilla: [],
       });
    },
    changeForm(index) {
      //this.$emit('selectForm', index);
    },
    addFormFields() {
      // FLYER - GIT
      if(this.form.content[this.idTypeStore].content_type == 6 
      || this.form.content[this.idTypeStore].content_type == 2 
      || this.form.content[this.idTypeStore].content_type == 1) {
        this.statusButtonPiece = false;
        const name = "";
        this.form.content[this.idTypeStore].parrilla.push({
          content_grid_id: null,
          text: '',
          keywords: [],
          notes: '',
          references: '',
          variants: 0,
          uuid: uuidv4(),
          files: [],
          titleTab: name,
        });
        setTimeout(() => {
          this.statusButtonPiece = true;
        }, 300);
      }else if(this.form.content[this.idTypeStore].content_type == 3 ||
        this.form.content[this.idTypeStore].content_type == 4 ||
        this.form.content[this.idTypeStore].content_type == 5) {
        this.statusButtonPiece = false;
        const name = "";
        // IGTV
        this.form.content[this.idTypeStore].parrilla.push({
          content_grid_id: null,
          start: '',
          body: '',
          outcome: '',
          end: '',
          closing: '',
          keywords: [],
          notes: '',
          references: '',
          uuid: uuidv4(),
          files: [],
          titleTab: name,
          variants: 0,
        });
        setTimeout(() => {
          this.statusButtonPiece = true;
        }, 300);
      }else if(this.form.content[this.idTypeStore].content_type == 7) {
        // CAROUSEL
        this.statusButtonPiece = false;
        const name = "";
        this.form.content[this.idTypeStore].parrilla.push({
          content_grid_id: null,
          pieces: [
            { text: '' },
            { text: '' },
            { text: '' },
          ],
          keywords: [],
          notes: '',
          references: '',
          uuid: uuidv4(),
          files: [],
          titleTab: name,
          variants: 0,
        });
        setTimeout(() => {
          this.statusButtonPiece = true;
        }, 300);
      }

      this.form.content[this.idTypeStore].pieces_qty = this.form.content[this.idTypeStore].parrilla.length;
    },
    async createSubTab(){
      if ((this.typeModal == 'EDIT' || this.typeModal == 'ONLY_VIEW') && this.form.content[this.idTypeStore].content_id) {
        if(this.form.content[this.idTypeStore] && this.form.content[this.idTypeStore].parrilla){
          if( (this.form.content[this.idTypeStore].parrilla.length > 0)) {
            this.form.content[this.idTypeStore].parrilla.splice(0, 1); //delete key 0
          }
        }

        this.statusButtonPiece = false;
        this.statusFlyer = false;
        this.statusCarousel = false;
        this.statusIgtv = false;
        this.statusNext = false;
        
        const params = {
          requestId: this.form.content[this.idTypeStore].content_id,
          userId: this.currentUser.user_id,
        };
        const result = await CreativeDesignService.getDataByRequestId(params);
        if(result) {
          this.form.content[this.idTypeStore].parrilla = JSON.parse(result.data[0].parrilla);

          this.form.content[this.idTypeStore].parrilla.forEach(element => {
            //validate null in type carousel
            if(this.form.content[this.idTypeStore].content_type == 7 && element.pieces) { 
              element.pieces.forEach(elementz => {
                elementz.text == 'null' ? elementz.text = '' : '';
              });
            }

            element.start && element.start == 'null' ? element.start = '' : '';
            element.text && element.text == 'null' ? element.text = '' : '';
            element.notes && element.notes == 'null' ? element.notes = '' : '';
            element.references && element.references == 'null' ? element.references = '' : '';
            element.body  && element.body == 'null' ? element.body = '' : '';
            element.outcome && element.outcome == 'null' ? element.outcome = '' : '';
            element.end && element.end == 'null' ? element.end = '' : '';
            element.closing  && element.closing == 'null' ? element.closing = '' : '';
          });
          this.FORM_DESIGN_REQUEST(this.form);

          setTimeout(() => {
            this.statusButtonPiece = true;
            this.statusFlyer = true;
            this.statusCarousel = true;
            this.statusIgtv = true;
            this.statusNext = true;
          }, 100);
          
        }
      }else{
        this.statusCarousel = false;

        this.form.content[this.idTypeStore].parrilla = [];
        if (this.form.content[this.idTypeStore].pieces_qty > this.form.content[this.idTypeStore].parrilla.length) {
          for (let index = 0; index < parseInt(this.form.content[this.idTypeStore].pieces_qty ); index++) {
            this.form.content[this.idTypeStore].parrilla.push({
              content_grid_id: null,
              text: '',
              start: '',
              body: '',
              outcome: '',
              end: '',
              closing: '',
              keywords: [],
              notes: '',
              references: '',
              uuid: uuidv4(),
              files: [],
              titleTab: '00',
              variants: 0,
              pieces: [
                {
                  piece_id: null,
                  text: "",
                  deleted: false,
                },
              ],
            });
          }
        }
        this.statusNext = true;
        setTimeout(() => {
          this.statusCarousel = true;
        }, 300);

        //this.form.content[this.idTypeStore].parrilla = []
      }
    },
    async getRequestContentGrid() {
      this.viewNumber = 2;
      this.UPDATE_ID_TYPE_CREATIVE_DESIGN(0);
      this.form.content[0].content_type = this.dataRequest.content_type_id;

      if (this.dataRequest.content_type_id == 1 ||
      this.dataRequest.content_type_id == 2 ||
      this.dataRequest.content_type_id == 6) {
        this.statusFlyer = true;
      } else if (this.dataRequest.content_type_id == 3 ||
      this.dataRequest.content_type_id == 4 ||
      this.dataRequest.content_type_id == 5) {
        this.statusIgtv = true;
      } else if (this.dataRequest.content_type_id == 7) {
        this.statusCarousel = true;
      }

      const params = {
        content_type_id: this.dataRequest.content_type_id,
        content_grid_id: this.dataRequest.detail_id,
      };
      const resultGrid = await CreativeDesignService.getContentGridById(params);
      if(resultGrid) {
        resultGrid.data[0].keywords = JSON.parse(resultGrid.data[0].keywords);
        if(this.dataRequest.content_type_id == 7){
          resultGrid.data[0].pieces =  JSON.parse(resultGrid.data[0].pieces);
        }
        this.form.content[0].parrilla[0] = resultGrid.data[0];
      }
    },
    refreshDataRequestCreative(){
      this.createSubTab();
    },
    beforeItem() {
      if (this.idPiece > 0) {
        this.UPDATE_ID_PIECE_CREATIVE_DESIGN(this.idPiece - 1);
      }
    },
    validateNextBtn() {
        if ((this.idPiece + 1) < this.form.content[this.idTypeStore].parrilla.length) {
          this.UPDATE_ID_PIECE_CREATIVE_DESIGN(this.idPiece + 1);
        }
    },
    async deleteContent(value, index){
      const confirm = await this.showConfirmSwal();
      if(confirm.value) {
        if(this.form.content[index].content_id){ //old type
          const params = {
            contentRequestId: this.form.content[index].content_id,
            userId: this.currentUser.user_id,
          };
          const resultDeleted = await CreativeDesignService.deleteDesignContentRequest(params);
          if(resultDeleted){
            this.form.content.splice(index, 1);
          }
        }else{ //new type
          this.form.content.splice(index, 1);
        }
        this.filterOptions();
      }
    },
    saveNotification(){
      this.update();
    },
    changeFormStatus(status){
      this.formStatusGlobal = status;
    }
  },
};
</script>
<style lang="scss">
.conten_programs_dark{
  border:solid 1px #fff6;
  padding: 10px 0;
  border-radius:0 10px 10px 10px;
  cursor:pointer;
}
.conten_programs_light{
  border:solid 1px #d8d6de;
  padding: 10px 0;
  border-radius:0 10px 10px 10px;
  cursor:pointer;
}

%conten_ico_program{
  display:flex;
  align-items: center;
  justify-content: center;
  border:solid 1px #4e4747;
  width:135px;
  margin-right:5px;
  padding: 5px;
  border-radius:5px;
  cursor:pointer;
}
%custom-control-label{
  width: 86px;
  padding: 0 5px;
  min-height:42px;
  cursor:pointer;
}
.conten_ico_program_dark{
  @extend %conten_ico_program;
  border:solid 1px #4e4747;
    .custom-control-label{
      @extend %custom-control-label;
    }
}
.conten_ico_program_light{
  @extend %conten_ico_program;
  border:solid 1px #d8d6de;
    .custom-control-label{
        @extend %custom-control-label;
    }
}
.conten_ico_program_dark:hover, .conten_ico_program_light:hover{
  border:solid 1px #0090e7;
  color:#0090e7;
}
.active_program{
  border:solid 1px #0090e7;
  color:#0090e7;
}
.min-w-160{
  min-width:160px;
}
.error_input{
  border:solid 1px #f00
}
.v_select .vs__dropdown-toggle{
  border-color: #f00 !important;
}
.btn_before{
  position: absolute;
  top: 50%;
  left: 8px;
  z-index:9999;
  padding: 24px 3px;
}
.btn_after{
  position:absolute;
  top: 50%;
  right: 8px;
  z-index:9999;
  padding: 24px 3px;
}

.content_flat .input{
  border:solid 1px #f00 !important;
}
</style>
