<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        class="mt-1"
      >
        <div
          class="wizard-navigation"
          style="overflow:auto;"
        >
        
          <ul class="nav nav-pills">
            <li
              v-for="(value, index) in listContentType()"
              :key="'li' + index"
              class="li_nav"
              @click="selectTabType(value, index)"
            >
              <b-button
                :variant="selectTabTypeId == index ? 'primary': isDarkSkin ? 'dark' : 'light' "
              >
                {{ value.name }}
                <feather-icon
                  icon="ChevronRightIcon"
                  size="16"
                />
              </b-button>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import CreativeDesignService from '@/views/creative/views/creative-requests/service/CreativeDesign.service.js';

export default {
  name: 'FormButtons',
  components: {

  },
  props: {
    dataRequest: {
      type: Object,
      required: false,
      default: null,
    },
    typeModal: {
      type: String,
      default: 'NEW',
      required: false,
    },
    statusFormGlobal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selectTabTypeId: 0,
      cloneStatusFormGlobal: null,
    };
  },

  watch: {
    statusFormGlobal(newVal) {
      this.updateFormGlobalStatus();
    },
  },

  async created() {

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreativeDesign() {
      return this.$route.matched[0].meta.module == 27;
    },
  },
  async mounted() {
    await this.createPieces();
    this.UPDATE_ID_TYPE_CREATIVE_DESIGN(0);
    this.$emit('createSubTab', 0);
    this.updateFormGlobalStatus();
  },
  methods: {
    ...mapActions({
      UPDATE_ID_TYPE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      UPDATE_ID_PIECE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_PIECE_CREATIVE_DESIGN',
      FORM_DESIGN_REQUEST: 'CreativeDesign/A_FORM_DESIGN_REQUEST',
    }),
    listContentType() {
      return this.dataRequest.content;
    },
    selectTabType(value, index) {
      if(index != this.idTypeStore && this.typeModal == 'EDIT' && this.statusFormGlobal){
        let title = 'Are you sure?';
        let text = "Switching tabs will discard the current form information. Are you sure you want to proceed?";
        let swalInfoIcon = '';
        this.$swal({
          title,
          text,
          imageUrl: '/assets/images/icons/swal/warning.svg',
          imageWidth: 70,
          showCancelButton: true,
          confirmButtonText: "Save",
          cancelButtonText: "Dont save",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$emit('saveNotification')

            //this.statusEditPieces = true;
            this.selectTabTypeId = index;
            this.UPDATE_ID_TYPE_CREATIVE_DESIGN(index);
            this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
            if (this.typeModal == 'EDIT' || this.typeModal == 'ONLY_VIEW') {
              this.$emit('createSubTab', value);
            }
          }else{
            this.selectTabTypeId = index;
            this.UPDATE_ID_TYPE_CREATIVE_DESIGN(index);
            this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
            if (this.typeModal == 'EDIT' || this.typeModal == 'ONLY_VIEW') {
              this.$emit('createSubTab', value);
            }
          }
        })

        this.cloneStatusFormGlobal = false;
        this.$emit('changeFormStatus', false);

      }else{
        this.selectTabTypeId = index;
        this.UPDATE_ID_TYPE_CREATIVE_DESIGN(index);
        this.UPDATE_ID_PIECE_CREATIVE_DESIGN(0);
        if (this.typeModal == 'EDIT' || this.typeModal == 'ONLY_VIEW') {
          this.$emit('createSubTab', value);
        }
      }
    },
    createPieces() {
      this.dataRequest.content.forEach((element, keyz) => {
        for (let index = 0; index < parseInt(element.pieces_qty); index++) {
          if (element.content_type === 6 || element.content_type === 2 || element.content_type === 1) {
            // FLYER - GIT
            if (element.parrilla && (element.pieces_qty > element.parrilla.length)) {
              element.parrilla.push(
                {
                  text: '',
                  keywords: [],
                  notes: '',
                  references: '',
                  variants: 0,
                  uuid: uuidv4(),
                  files: [],
                  titleTab: element.name,
                },
              );
            } else if (this.typeModal == 'EDIT') {
              element.parrilla = [];
            }
          } else if (element.content_type === 3 || element.content_type === 4 || element.content_type === 5) {
            // IGTV
            if (element.parrilla && (element.pieces_qty > element.parrilla.length)) {
              element.parrilla.push(
                {
                  start: '',
                  body: '',
                  outcome: '',
                  end: '',
                  closing: '',
                  keywords: [],
                  notes: '',
                  references: '',
                  uuid: uuidv4(),
                  files: [],
                  titleTab: element.name,
                  variants: 0,
                },
              );
            } else if (this.typeModal == 'EDIT') {
              element.parrilla = [];
            }
          } else if (element.content_type === 7) {
            // CAROUSEL
            if (element.parrilla && (element.pieces_qty > element.parrilla.length)) {
              element.parrilla.push(
                {
                  pieces: [
                    { text: '' },
                    { text: '' },
                    { text: '' },
                  ],
                  keywords: [],
                  notes: '',
                  references: '',
                  uuid: uuidv4(),
                  files: [],
                  titleTab: element.name,
                  variants: 0,
                },
              );
            } else if (this.typeModal == 'EDIT') {
              element.parrilla = [];
            }
          }
        }
      });
      this.FORM_DESIGN_REQUEST(this.dataRequest);
    },
    updateFormGlobalStatus() {
      this.cloneStatusFormGlobal = this.statusFormGlobal;
    },
  },
};
</script>
  <style scoped>
  .nav-pills{
      display:flex;
      width:max-content;
  }
  .nav-pills > li > a {
      border: 0 !important;
      border-radius: 0;
      line-height: 18px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      min-width: 100px;
      text-align: center;
      color: #fff9 !important;
  }
  .nav>li>a {
      position: relative;
      display: block;
      padding: 10px 15px;
  }
  .title_tab{
      font-weight:bold;
      cursor:pointer;
      margin-left: 2px;
  }
  .title_tab:hover, .active_tab{
      color:rgb(115, 103, 240);
  }
  .scroll-area {
    position: relative;
    margin: auto;
    width: 500px;
    height: 30px;
    border:solid 1px #f00;
  }
  .li_nav{
    width:auto;
  }
  </style>
