<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div
          class="wizard-navigation"
          style="overflow:hidden;"
        >
          <div
            ref="content_parrilla"
            class="container efecct_content"
          >
            <div class="d-flex justify-content-end"
              v-if="typeModal !='VIEW'">
              <b-button variant="primary" size="sm" @click="addPieces" :disabled="typeModal == 'ONLY_VIEW'">add piece</b-button>
            </div>
            <div class="box">
              <b-row>
                <b-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <validation-observer ref="form">
                    <!-- listData.parrilla[selectForm].pieces -->
                    <div
                      v-for="(datox, keyz) in pieces"
                      :key="'dim'+keyz"
                      :class=" keyz > 0 ? 'mt-1' : '' "
                    >
                      <div class="text-capitalize">
                        P{{ keyz + 1 }}
                      </div>

                      <div style="position:relative;"
                      v-if="typeModal !='VIEW'"
                      >
                        <b-button       
                          v-if="keyz >= 1"
                          v-b-tooltip.hover.top="'Deleted'"
                          :variant="datox.deleted ? 'info' : 'danger'"
                          class="delete_icon_dark"
                          @click="deletePieces(keyz)"
                          :disabled="typeModal == 'ONLY_VIEW'"
                        >
                          <feather-icon

                            :icon="datox.deleted ? 'RotateCcwIcon' : 'Trash2Icon'"
                            size="12"
                            class=""
                          />
                        </b-button>
                      </div>

                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].pieces[keyz].text"
                          :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                          :state="errors.length > 0 ? false : null "
                          @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Keywords
                    </div>
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-tags
                          v-if="statusInputKeywords"
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].keywords"
                          input-id="tags-basic"
                          :placeholder="typeModal == 'VIEW' ? '':'Add tag...'"
                          :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                          :state="errors.length > 0 ? false : null "
                          @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Notes
                    </div>
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].notes"
                          :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                          :state="errors.length > 0 ? false : null "
                          @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      References
                    </div>
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].references"
                          :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                          :state="errors.length > 0 ? false : null "
                          @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Variants
                    </div>
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="{required:true, min_value:0, min:1, max:99}"
                      >
                        <b-form-input
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].variants"
                          v-mask="'###'"
                          placeholder="Variants"
                          :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                          :state="errors.length > 0 ? false : null "
                          @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div>
                      <b-form-input
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].uuid"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                        class="mt-2 d-none"
                      />
                    </div>

                    <div class="text-capitalize mt-2">
                      Files
                    </div>
                    <div v-if="showComponent">
                      <div v-if="typeModal != 'ONLY_VIEW'">
                        <drag-and-drop-files
                          v-if="(isCreativeDesign && typeModal == 'NEW' || isCreativeDesign && typeModal == 'EDIT') "
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].files"
                          :files-array=" typeModal == 'EDIT' || typeModal == 'VIEW' ? [] : dataRequest.content[idTypeStore].parrilla[idPiece].files"
                          :single="false"
                          :image="true"
                        />
                      </div>
                    </div>

                    <div
                      v-if="(isCreativeDesign && (typeModal == 'EDIT' || typeModal == 'VIEW')) || !isCreativeDesign || typeModal == 'ONLY_VIEW'"
                    >
                      <files-grid
                        class="mt-2"
                        :paths="dataRequest.content[idTypeStore].parrilla[idPiece].paths"
                        :typeGrid="typeModal"
                        @refreshDataRequest="refreshDataRequest"
                      />
                    </div>
                  </validation-observer>

                </b-col>
              </b-row>
            </div>
          </div>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormButtons from '@/views/creative/views/creative-requests/components/modals/FormButtons.vue';
import FilesGrid from '@/views/creative/views/creative-requests/components/modals/FilesGrid.vue';
import DragAndDropFiles from '@/views/commons/utilities/DragAndDrop.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ContentTypesPiece',
  components: {
    FormButtons,
    DragAndDropFiles,
    FilesGrid,
  },
  props: {
    typeModal: {
      type: String,
      default: 'NEW',
      required: false,
    },
    dataRequest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      keywords: ['Credit', 'Experts', 'Solutions'],
      listData: {
        requestName: 'request1',
        contentType: 2,
        parrilla: [
          {
            pieces: '',
            uuid: uuidv4(),
            files: [],
          },
        ],
      },
      selectForm: 0,
      showComponent: true,
      pieces: [],
      statusInputKeywords: false,
      changeFormStatus: false,
    };
  },
  async created() {
    this.pieces = this.dataRequest.content[this.idTypeStore].parrilla[this.idPiece].pieces;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreativeDesign() {
      return this.$route.matched[0].meta.module == 27;
    },
  },
  watch: {
    idPiece(newVal) {
      if (newVal >= 0) {
        this.selectForm = this.idPiece;
        this.addClassPlayEfect();
        this.statusInputKeywords = false;
        setTimeout(() => {
          this.statusInputKeywords = true;
        }, 150);
      }
      this.showComponent = false;
      setTimeout(() => {
        this.showComponent = true;
      }, 300);

      this.pieces = this.dataRequest.content[this.idTypeStore].parrilla[this.idPiece].pieces
    },
    idTypeStore() {
      this.showComponent = false;
      setTimeout(() => {
        this.showComponent = true;
      }, 300);
    },
  },
  mounted() {
    //this.updateList();
    this.idPiece > 0 ? this.selectForm = this.idPiece : '';
    

    this.statusInputKeywords = false;
    setTimeout(() => {
      this.statusInputKeywords = true;
    }, 150);
  },
  methods: {
    ...mapActions({
      UPDATE_ID_PIECE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    listContentType() {
      return parseInt(this.dataRequest.pieces_qty);
    },
    changeForm(index) {
      this.selectForm = index;
    },
    addPieces(){
      const input = {
        text: null,
        piece_id: null
      }
      this.dataRequest.content[this.idTypeStore].parrilla[this.idPiece].pieces.push(input);
      this.pieces = this.dataRequest.content[this.idTypeStore].parrilla[this.idPiece].pieces
    },
    deletePieces(index) {
      if(this.pieces[index].piece_id != null){
        let valueDeleted = !this.pieces[index].deleted;
        this.pieces[index].deleted = valueDeleted;
        this.dataRequest.content[this.idTypeStore].parrilla[this.idPiece].pieces[index].deleted = valueDeleted;
      }else{
        this.pieces.splice(index, 1);
      }
    },
    addClassPlayEfect() {
      const refParrilla = this.$refs.content_parrilla;
      refParrilla.classList.add('play_efect');
      setTimeout(() => {
        refParrilla.classList.remove('play_efect');
      }, 400);
    },
    refreshDataRequest() {
      this.$emit('refresh-data-request-creative');
    },
    async validateForm() {
      this.isValidate = [];
      this.dataRequest.content[this.idTypeStore].parrilla.forEach((element, key) => {
        if (element.text === '' || element.keywords === '' || element.notes === '' || element.references === '') {
          this.isValidate.push(key);
          this.UPDATE_ID_PIECE_CREATIVE_DESIGN(key);
        }
      });

      if (this.isValidate.length > 0) {
        await this.$refs.form.validate();
        return false;
      }
      return true;
    },
    watchChangeValue() {
      this.changeFormStatus = true;
      this.$emit('changeFormStatus', this.changeFormStatus);
    },
  },
};
</script>
<style scoped>
.nav-pills{
    display:flex;
}
.nav-pills > li > a {
    border: 0 !important;
    border-radius: 0;
    line-height: 18px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    text-align: center;
    color: #fff9 !important;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

/* animacion */
.container{
    height:100%;
    padding:0px;
}
.delete_icon_dark{
  position: absolute;
  right: 1px;
  padding: 5px 8px;
  bottom: -26px;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.efecct_content {
  left: 0px;
  height:100%;
}
.efecct_content.play_efect {
  height:100%;
  position:relative;
  -webkit-animation: fadeOut 0.4s;
}
</style>
