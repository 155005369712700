const { amgApi } = require("@/service/axios");

class CreativeDesignService {
    async saveRequest(params){
        try {
            const data = await amgApi.post("/creative/creative-request/save-request", params);
            return data;
        } catch (error) {
            throw error;
        }
    }
    
    async getPrograms(){
        try {
            const data = await amgApi.get("/creative/creative-request/get-programs");
            return data
        } catch (error) {
            throw error;
        }
    }

    async getMaterialTypes(){
        try {
            const data = await amgApi.get("/creative/creative-request/get-material-types");
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getRequests(params){
        try {
            const data = await amgApi.post("/creative/creative-request/get-requests", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getRequestDesign(params){
        try {
            //const data = await amgApi.post("/creative/creative-request/get-requests-by-id", params);
            const data = await amgApi.post("/creative/creative-request/get-request-content", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDataByRequestId(params){
        try {
            const data = await amgApi.post("/creative/creative-request/get-requests-by-id", params);
            return data;
        } catch (error) {
            throw error;
        }
    }


    


    async updateRequest(params){
        try {
            const data = await amgApi.post("/creative/creative-request/update-content-detail-request", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDataChatByMaterialId(params){
        try {
            const data = await amgApi.post("/creative/creative-request/get-data-chat-by-material-id", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async sendMessageChatMaterial(params){
        try {
            const data = await amgApi.post("/creative/creative-request/send-message-chat-material", params);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async deleteContentGrid(params){
        try {
            const data = await amgApi.post("/creative/creative-request/delete-content-grid", params);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getContentGridById(params){
        try {
          const data = await amgApi.post("/creative/creative-request/get-content-grid-by-id", params);
          return data;
        } catch (error) {
          throw error;
        }
    }
    async deleteDesignContentRequest(params){
        try {
          const data = await amgApi.post("/creative/creative-request/delete-design-content-request", params);
          return data;
        } catch (error) {
          throw error;
        }
    }
    



}

export default new CreativeDesignService();