<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <div class="wizard-navigation">
          <div
            ref="content_parrillas"
            class="container px-0 efecct_content"
          >
            <div class="box">
              <b-row>
                <b-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                  <validation-observer ref="form">

                    <div class="text-capitalize">
                      Start
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].start"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Body
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].body"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Outcome
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].outcome"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      End
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].end"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Closing
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].closing"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Keywords
                    </div>
                    <div class="mt-2">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-tags
                          v-model="dataRequest.content[idTypeStore].parrilla[idPiece].keywords"
                          input-id="tags-basic"
                          :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                          :state="errors.length > 0 ? false : null "
                          @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Notes
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].notes"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      References
                    </div>
                    <div>
                      <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      >
                      <b-form-textarea
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].references"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                      />
                      </ValidationProvider>
                    </div>

                    <div class="text-capitalize mt-2">
                      Variants
                    </div>
                    <div>
                      <ValidationProvider
                        v-slot="{ errors }"
                        :rules="{required:true, min_value:0, min:1, max:99}"
                      >
                        <b-form-input
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].variants"
                        v-mask="'###'"
                        placeholder="Variants"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW' || typeModal=='VIEW'"
                        :state="errors.length > 0 ? false : null "
                        @input="watchChangeValue()"
                        />
                      </ValidationProvider>
                    </div>

                    <div>
                      <b-form-input
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].uuid"
                        :disabled="!isCreativeDesign || typeModal == 'ONLY_VIEW'"
                        class="mt-2 d-none"
                      />
                    </div>

                    <div class="text-capitalize mt-2">
                      Files
                    </div>
                    <div v-if="typeModal != 'ONLY_VIEW'">
                      <drag-and-drop-files
                        v-if="showComponent && (isCreativeDesign && typeModal == 'NEW' || isCreativeDesign && typeModal == 'EDIT') "
                        v-model="dataRequest.content[idTypeStore].parrilla[idPiece].files"
                        :files-array="dataRequest.content[idTypeStore].parrilla[idPiece].files"
                        :single="false"
                        :image="true"
                      />
                    </div>

                  </validation-observer>

                  <div
                    v-if="(isCreativeDesign && (typeModal == 'EDIT' || typeModal == 'VIEW')) || !isCreativeDesign ||  typeModal == 'ONLY_VIEW'"
                  >
                    <files-grid
                      class="mt-2"
                      :paths="dataRequest.content[idTypeStore].parrilla[idPiece].paths"
                      :typeGrid="typeModal"
                      @refreshDataRequest="refreshDataRequest"
                    />
                  </div>

                </b-col>
              </b-row>
            </div>
          </div>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormButtons from '@/views/creative/views/creative-requests/components/modals/FormButtons.vue';
import FilesGrid from '@/views/creative/views/creative-requests/components/modals/FilesGrid.vue';
import DragAndDropFiles from '@/views/commons/utilities/DragAndDrop.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ContentTypesPiece',
  components: {
    FormButtons,
    DragAndDropFiles,
    FilesGrid,
  },
  props: {
    typeModal: {
      type: String,
      default: 'NEW',
      required: false,
    },
    dataRequest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      keywords: ['Credit', 'Experts', 'Solutions'],
      listData: {
        requestName: '',
        contentType: '',
        parrilla: [
          {
            start: '',
            files: [],
          },
        ],
      },
      selectForm: 0,
      showComponent: true,
      changeFormStatus: false,
    };
  },
  async created() {

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      idTypeStore: 'CreativeDesign/G_REFRESH_ID_TYPE_CREATIVE_DESIGN',
      idPiece: 'CreativeDesign/G_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreativeDesign() {
      return this.$route.matched[0].meta.module == 27;
    },
    validateFiles() {
      return thi.dataRequest.content[this.idTypeStore].parrilla[this.idPiece].files;
    }
  },
  watch: {
    idPiece(newVal) {
      if (newVal >= 0) {
        this.selectForm = this.idPiece;
        this.addClassPlayEfect();
      }
      this.showComponent = false;
      setTimeout(() => {
        this.showComponent = true;
      }, 300);
    },
    idTypeStore() {
      this.showComponent = false;
      setTimeout(() => {
        this.showComponent = true;
      }, 300);
    },
  },
  mounted() {
    this.idPiece > 0 ? this.selectForm = this.idPiece : '';
    setTimeout(() => {
      this.showComponent = true;
    }, 300);
  },
  methods: {
    ...mapActions({
      UPDATE_ID_PIECE_CREATIVE_DESIGN: 'CreativeDesign/A_REFRESH_ID_PIECE_CREATIVE_DESIGN',
    }),
    listContentType() {
      return parseInt(this.dataRequest.pieces_qty);
    },
    changeForm(index) {
      this.selectForm = index;
    },
    addFormFields() {
      this.listData.parrilla.push(
        {
          start: '',
          body: '',
          outcome: '',
          end: '',
          closing: '',
          keywords: [],
          notes: '',
          references: '',
          deleted: false,
          content_grid_id: null,
          uuid: uuidv4(),
          files: [],
        },
      );
      this.dataRequest.pieces_qty = this.listData.parrilla.length;
      this.dataRequest.parrilla = this.listData.parrilla;
    },
    addClassPlayEfect() {
      const refParrilla = this.$refs.content_parrillas;
      refParrilla.classList.add('play_efect');
      setTimeout(() => {
        refParrilla.classList.remove('play_efect');
      }, 400);
    },
    refreshDataRequest() {
      this.$emit('refresh-data-request-creative');
    },
    async validateForm() {
      this.isValidate = [];
      this.dataRequest.content[this.idTypeStore].parrilla.forEach((element, key) => {
        if (element.text === '' || element.keywords === '' || element.notes === '' || element.references === '') {
          this.isValidate.push(key);
          //this.selectForm = key;
          this.UPDATE_ID_PIECE_CREATIVE_DESIGN(key);
        }
      });

      if (this.isValidate.length > 0) {
        const isValid = await this.$refs.form.validate();
        return false;
      }
      return true;
    },
    watchChangeValue() {
      this.changeFormStatus = true;
      this.$emit('changeFormStatus', this.changeFormStatus);
    },
  },
};
</script>
  <style scoped>
  .nav-pills{
      display:flex;
  }
  .nav-pills > li > a {
      border: 0 !important;
      border-radius: 0;
      line-height: 18px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      min-width: 100px;
      text-align: center;
      color: #fff9 !important;
  }
  .nav>li>a {
      position: relative;
      display: block;
      padding: 10px 15px;
  }

  /* animacion */
  .container{
      height:100%;
  }

  @keyframes fadeOut {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.efecct_content {
  left: 0px;
  height:100%;
}
.efecct_content.play_efect {
  height:100%;
  position:relative;
  -webkit-animation: fadeOut 0.4s;
}
</style>
